<template>
    <div class="pc-register">
    </div>
</template>

<script>
export default {
    name: 'PcRegister'
}
</script>
<style lang="less">
    .pc-register {
        width: 500px;
        height: 100vh;
        margin: 0 auto;
    }
</style>
