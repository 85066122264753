<template>
    <div class="mobile-register">
    </div>
</template>

<script>
export default {
    name: 'MobileRegister'
}
</script>
<style lang="less">
.mobile-register {
    width: 100vw;
    height: 100vh;
}
</style>
